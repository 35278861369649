import React, { useState, useEffect } from "react"
import Div100vh from "react-div-100vh"
import styles from "./skitripForm.module.scss"
import logo from "../../../images/winter2022/logo_blue.png"
import close from "../../../images/winter2022/closeIcon.png"
import { navigate } from "gatsby"

import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Autocomplete from "@material-ui/lab/Autocomplete"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import provincias from "../../../provincias.json"
import localidades from "../proviciasylocalidades.json"
import loadgif from "../../../images/loading.gif"
import { events, cualiQuestions, preferenceQuestions } from "../utils"
import { validateEmail, formatDate } from "../../../utils/formFunctions"
import Cookies from "js-cookie"
import bases from "../../../../static/winter-sunsets/BBCC_Corona_Invierno_Winter.pdf"
import PP from "../../../../static/PP_AO.pdf"

const theme = createTheme({
  palette: {
    primary: { main: "#0c2340" },
    secondary: { main: "#11cb5f" },
  },
})

const SkitripForm = () => {
  const [validateError, setValidateError] = useState("")
  const [loading, setLoading] = useState(false)
  const [cities, setCities] = useState([])
  const [form, setForm] = useState({
    fullName: "",
    birthday: "",
    email: "",
    state: "",
    city: "",
    question: "",
    tyc: "",
    event: "",
    preferenceQuestion: "",
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = e => {
    const { name, value } = e.target
    setForm(form => ({ ...form, [name]: value }))
  }

  const MessageValidate = () => {
    switch (validateError) {
      case "email":
        return <h3 className={styles.error}>EL EMAIL ES INVÁLIDO</h3>
      case "empty":
        return <h3 className={styles.error}>DEBE COMPLETAR TODOS LOS CAMPOS</h3>
      case "terms":
        return (
          <h3 className={styles.error}>
            DEBE ACEPTAR LOS TÉRMINOS Y CONDICIONES
          </h3>
        )
      case "success":
        return null
      default:
        return null
    }
  }

  const apiUrl = "https://wacoronabe-prd.azurewebsites.net/api"
  // const apiUrl = "http://localhost:8080/api"

  const handleSubmit = e => {
    e.preventDefault()
    let {
      fullName,
      birthday,
      email,
      state,
      city,
      question,
      event,
      preferenceQuestion,
    } = form
    if (fullName && birthday && question && email && state && city) {
      if (validateEmail(email)) {
        if (form.tyc) {
          setValidateError("")
          let td = Cookies.get("_td")
          let obj = {
            fullName,
            birthday,
            email,
            state,
            city,
            question,
            event,
            preferenceQuestion,
            td,
          }
          setLoading(true)

          fetch(`${apiUrl}/wintersnowtrip/`, {
            method: "POST",
            body: JSON.stringify(obj),
            // credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }).then(response => {
            window.dotq = window.dotq || []
            window.dotq.push({
              projectId: "10000",
              properties: {
                pixelId: "10096203",
                qstrings: {
                  et: "custom",
                  ea: "winter22experiencias",
                },
              },
            })
            if (response.ok) {
              navigate("/winter/success", {
                state: { event: "skitrip" },
              })
            } else {
              console.error("Error guardando los datos. \n")
            }
          })
        } else {
          setValidateError("terms")
        }
      } else {
        setValidateError("email")
      }
    } else {
      setValidateError("empty")
    }
  }

  useEffect(() => {
    const localidadesArr = Object.entries(localidades)
    if (form.state) {
      const filter = localidadesArr.filter(([key, value]) => {
        return key === form.state
      })
      const justStrings = Object.fromEntries(filter)
      setCities(justStrings[form.state])
    }
  }, [form.state])

  return (
    <MuiThemeProvider theme={theme}>
      <Div100vh className={styles.container}>
        <div className={styles.form}>
          <img
            src={close}
            className={styles.closeIcon}
            onClick={() => navigate("/winter")}
          />
          <div className={styles.header}>
            <img src={logo} alt="corona logo" className={styles.logo} />
            <h4 className={styles.descubri}>
              COMPLETÁ LOS DATOS Y PARTICIPÁ <br />
              POR UN
              <span className={styles.highlight}> SNOW TRIP </span>
            </h4>
            <p>
              Registrate, seguí a{" "}
              <a href="https://www.instagram.com/swell.trips/">
                {" "}
                @swell.trips{" "}
              </a>{" "}
              y a{" "}
              <a href="https://www.instagram.com/baibaisurftrip/">
                @baibaisurftrip{" "}
              </a>{" "}
              <br />y ya estás participando de nuestra experiencia de invierno.
            </p>
          </div>

          <form className={styles.forminputs} noValidate autoComplete="off">
            <div className={styles.row}>
              <div className={`${styles.largo}`}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  required={true}
                  name="fullName"
                  label="Nombre y Apellido"
                  value={form.fullName}
                  onChange={handleChange}
                  className={styles.inputfield}
                  InputLabelProps={{
                    style: {
                      paddingLeft: "1%",
                    },
                  }}
                />
              </div>
              <div className={`${styles.largo}`}>
                <TextField
                  id="outlined-basic3"
                  variant="outlined"
                  required={true}
                  name="birthday"
                  label="Fecha de Nacimiento"
                  value={form.birthday}
                  onChange={({ target }) => {
                    const onlyNums = target.value.replace(/[^0-9]/g, "")
                    const date = formatDate(onlyNums)
                    setForm({ ...form, birthday: date })
                  }}
                  className={styles.inputfield}
                  InputLabelProps={{
                    style: {
                      paddingLeft: "1%",
                    },
                  }}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={`${styles.largo}`}>
                <TextField
                  select
                  id="outlined-select-currency"
                  variant="outlined"
                  required={true}
                  name="state"
                  label="Provincia"
                  value={form.state}
                  onChange={handleChange}
                  className={styles.inputfield}
                >
                  {provincias.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className={`${styles.largo}`}>
                <Autocomplete
                  name="localidades"
                  id="combo-box-demo"
                  inputProps={{ inputMode: "numeric" }}
                  onChange={(event, newValue) => {
                    setForm({ ...form, city: newValue })
                  }}
                  options={cities.sort((a, b) => -b.localeCompare(a))}
                  getOptionLabel={city => city}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={styles.inputfield}
                      style={{ textAlign: "center" }}
                      label="Localidades"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={`${styles.largo}`}>
                <TextField
                  id="outlined-basic3"
                  variant="outlined"
                  required={true}
                  name="email"
                  label="Email"
                  value={form.email}
                  onChange={handleChange}
                  className={styles.inputfield}
                  InputLabelProps={{
                    style: {
                      paddingLeft: "1%",
                    },
                  }}
                />
              </div>

              <div className={`${styles.largo}`}>
                <TextField
                  select
                  id="outlined-select-currency"
                  variant="outlined"
                  required={true}
                  name="event"
                  label="¿A qué lugar te estás anotando?"
                  value={form.event}
                  onChange={handleChange}
                  className={styles.inputfield}
                >
                  {events.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={styles.row}>
              <div className={`${styles.largo}`}>
                <div className={styles.question}>
                  <TextField
                    id="outlined-select-currency"
                    name="question"
                    label="¿Cuánto te interesa que los productos que consumís sean sustentables?"
                    value={form.question}
                    onChange={handleChange}
                    variant="outlined"
                    required={true}
                    select
                    className={styles.inputfield}
                    InputLabelProps={{
                      style: {
                        paddingLeft: "1%",
                      },
                    }}
                  >
                    {cualiQuestions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={`${styles.largo}`}>
                <div className={styles.question}>
                  <TextField
                    id="outlined-select-currency"
                    name="preferenceQuestion"
                    label="¿En qué ocasiones acostumbras consumir cerveza?"
                    value={form.preferenceQuestion}
                    onChange={handleChange}
                    variant="outlined"
                    required={true}
                    select
                    className={styles.inputfield}
                    InputLabelProps={{
                      style: {
                        paddingLeft: "1%",
                      },
                    }}
                  >
                    {preferenceQuestions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
            <RadioGroup
              aria-label="gender"
              value={form.tyc}
              className={styles.inputradio}
              onChange={e => {
                setForm({ ...form, tyc: "success" })
              }}
              name="termsAndConditions"
            >
              <FormControlLabel
                value="success"
                color="primary"
                className={styles.radio}
                control={
                  <Radio
                    value="success"
                    color="default"
                    name="radio"
                    size="small"
                    inputProps={{ "aria-label": "D" }}
                  />
                }
              />
              <span className={styles.textbases}>
                He leído y acepto{" "}
                <a
                  href={"/TyCCervezaCorona.pdf"}
                  className={styles.acolor}
                  download
                  target="blank"
                >
                  termino y condiciones,{" "}
                </a>
                <a
                  className={styles.acolor}
                  href={bases}
                  download
                  target="_blank"
                >
                  bases y condiciones
                </a>
                ,{" "}
                <a className={styles.acolor} href={PP} download target="_blank">
                  políticas de privacidad
                </a>{" "}
                y activaciones de marketing.
              </span>
            </RadioGroup>
            <MessageValidate />
            <div className="d-flex justify-content-center">
              {!loading ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    className={styles.buttonvalidar}
                  >
                    PARTICIPAR
                  </Button>
                </>
              ) : (
                <img
                  src={loadgif}
                  style={{ width: "45px", margin: 0, padding: 0 }}
                />
              )}
            </div>
          </form>
        </div>
        <div className={styles.imagen}></div>
      </Div100vh>
    </MuiThemeProvider>
  )
}

export default SkitripForm
